body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  font-size: 16px;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.app {
  width: 100%;
  max-width: none;
  padding: 0;
  min-height: 100vh;
  background-color: #f5f7fa;
  color: #333;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin: 0;
}

.app-header {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  width: 100%;
  margin-bottom: 0;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
}

.left-section {
  flex: 1;
  text-align: left;
  padding-right: 20px;
  max-width: 70%;
}

.right-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  white-space: nowrap;
}

.app-header h1 {
  font-size: 32px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  text-align: left;
  color: inherit;
  letter-spacing: -0.5px;
}

.subheading {
  font-size: 16px;
  font-weight: 400;
  color: #666;
  margin: 8px 0 0 0;
  text-align: left;
  line-height: 1.4;
}

.view-count, .dark-mode-toggle {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0;
}

.view-count {
  margin-bottom: 8px;
  margin-right: 5px;
}

.view-count-label, .dark-mode-toggle span {
  margin-right: 18px;
  font-weight: 600;
}

.view-count-number {
  color: #007bff;
  font-weight: 600;
}

.feed-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.feed-container > div {
  flex: 0 0 calc(33.333% - 20px);
  margin-bottom: 20px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Dark mode styles */
.app.dark-mode {
  background-color: #1a1a1a;
  color: #f0f0f0;
}

.app.dark-mode .app-header {
  background-color: #2c2c2c;
  color: #f0f0f0;
}

.app.dark-mode .subheading {
  color: #ccc;
}

.app.dark-mode .view-count-number {
  color: #4da6ff;
}

.app.dark-mode .feed-container > div {
  background-color: #2c2c2c;
  color: #f0f0f0;
}

.app.dark-mode .feed-container a {
  color: #6699ff;
}

/* Override index.css styles */
.app .app-header {
  display: block;
  border-radius: 0;
}

.app .dark-mode-toggle {
  margin-top: 0;
}

/* Responsive design */
@media (max-width: 1200px) {
  .feed-container > div {
    flex: 0 0 calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .left-section {
    width: 100%;
    max-width: 100%;
    padding-right: 0;
    margin-bottom: 15px;
  }

  .right-section {
    width: 100%;
    align-items: flex-start;
  }

  .view-count, .dark-mode-toggle {
    margin-left: 0;
    margin-right: 20px;
  }

  .feed-container > div {
    flex: 0 0 100%;
  }
}

/* Creator attribution styles */
.creator-attribution {
  margin-top: 40px;
  padding: 20px;
  text-align: center;
  font-size: 0.9em;
  color: inherit;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  transition: opacity 0.3s ease;
}

.creator-attribution:hover {
  opacity: 1;
}