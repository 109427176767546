:root {
  --bg-color: #f5f7fa;
  --text-color: #333;
  --card-bg-color: #fff;
  --card-border-color: #eee;
  --link-color: #007bff;
  --link-hover-color: #0056b3;
  --button-bg: #f0f0f0;
  --button-text: #333;
  --toggle-bg-off: #ccc;
  --toggle-bg-on: #2196F3;
}

body.dark-mode {
  --bg-color: #1a1a1a;
  --text-color: #f0f0f0;
  --card-bg-color: #2c2c2c;
  --card-border-color: #444;
  --link-color: #4da6ff;
  --link-hover-color: #75b7ff;
  --button-bg: #444;
  --button-text: #f0f0f0;
}

body {
  font-family: -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  background-color: var(--bg-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.rss-feed-header {
  display: flex;
  align-items: stretch;
  background-color: var(--card-bg-color);
  border-radius: 8px 8px 0 0;
  overflow: hidden;
}

.rss-feed-title {
  flex-grow: 1;
  padding: 15px;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 1.2em;
  font-weight: bold;
}

.company-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #fff;
  width: 120px;
}

.company-logo {
  max-width: 100px;
  max-height: 40px;
  object-fit: contain;
}

.rss-feed {
  background-color: var(--card-bg-color);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow: hidden;
  transition: background-color 0.3s ease;
}

.rss-feed-content {
  padding: 15px;
}

.rss-feed ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.rss-feed li {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--card-border-color);
}

.rss-feed li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.rss-feed a {
  color: var(--link-color);
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.rss-feed a:hover {
  color: var(--link-hover-color);
}

.rss-feed p {
  color: var(--text-color);
  opacity: 0.7;
  font-size: 0.8em;
  margin: 5px 0 0 0;
}

.rss-feed.loading, .rss-feed.error {
  padding: 20px;
  text-align: center;
}

.error-message {
  color: #d32f2f;
}

/* Provider-specific styles */
.rss-feed.aws .rss-feed-title { background-color: #ff9900; }
.rss-feed.google .rss-feed-title { background-color: #4285F4; }
.rss-feed.azure .rss-feed-title { background-color: #0089D6; }

/* Dark mode toggle styles */
.dark-mode-toggle {
  display: flex;
  align-items: center;
}

.dark-mode-toggle span {
  margin-right: 10px;
  color: var(--text-color);
}

.toggle-switch-container {
  position: relative;
  width: 50px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
}

.toggle-switch-checkbox {
  display: none;
}

.toggle-switch-label {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--toggle-bg-off);
  border-radius: 24px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-switch-switch {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.toggle-switch-checkbox:checked + .toggle-switch-label {
  background-color: var(--toggle-bg-on);
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
  transform: translateX(26px);
}